import React from 'react';
import { injectIntl, intlShape } from 'gatsby-plugin-intl';

import Container from 'components/Container';
import './NotFoundPage.module.css';

const NotFoundPage = ({ intl }) => (
  <Container>
    <div styleName="container">
      <h4>
        {intl.formatMessage({ id: 'common.whoops' })}
      </h4>
      <p>
        {intl.formatMessage({ id: 'common.not-found' })}
      </p>
    </div>
  </Container>
);

NotFoundPage.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(NotFoundPage);
