import React from 'react';
import Helmet from 'react-helmet';

import Layout from 'components/Layout';
import NotFoundPage from 'components/pages/NotFoundPage';

const NotFound = () => (
  <Layout>
    <Helmet title="Not found" />

    <NotFoundPage />
  </Layout>
);

export default NotFound;
